<template>
  <v-navigation-drawer
    :value="isAddUpdateEventSidebarActive"
    temporary
    touchless
    floating
    stateless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    @input="val => $emit('update:is-add-update-event-sidebar-active', val)"
  >
    <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar size="48" color="secondary">
          <v-icon size="24" class="white--text">
            {{ formIcon }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">
          {{ formTitle }}
        </div>
      </div>
      <v-btn icon x-small @click="$emit('update:is-add-update-event-sidebar-active', false)">
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>

    <form>
      <validation-observer ref="observer">
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Durum" rules="required">
          <v-select
            v-model="eventData.is_active"
            label="Durum"
            :items="$store.state.tanimlar.durum"
            class="rounded-0"
            :dark="isDark"
            :menu-props="{ bottom: true, offsetY: true }"
            :error-messages="errors"
            filled
            hide-details
          ></v-select>
        </validation-provider>

        <!-- Başlık -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="Başlık" rules="required|max:500">
          <v-text-field
            v-model="eventData.baslik"
            filled
            :dark="isDark"
            label="Başlık"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-text-field>
        </validation-provider>
        <v-divider />

        <!-- Başlık -->
        <validation-provider v-slot="{ errors }" tag="div" mode="eager" name="İçerik" rules="required|max:500">
          <v-textarea
            v-model="eventData.content"
            filled
            :dark="isDark"
            label="İçerik"
            :error-messages="errors"
            hide-details
            class="rounded-0"
          ></v-textarea>
        </validation-provider>
      </validation-observer>
    </form>
    <template v-slot:append>
      <div class="pa-3">
        <v-btn x-large rounded block :loading="loading" color="secondary" @click="submit">
          {{ formButtonText }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiEye, mdiEyeOff, mdiFileTree, mdiPencil, mdiPlusThick, mdiUndo } from '@mdi/js'
import { TiptapVuetify } from 'tiptap-vuetify'
import Vue from 'vue'

export default {
  components: { TiptapVuetify },
  model: {
    prop: 'isAddUpdateEventSidebarActive',
    event: 'update:is-add-update-event-sidebar-active',
  },
  props: {
    isAddUpdateEventSidebarActive: {
      type: Boolean,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
    eventItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isDark } = useAppConfig()

    return {
      isDark,

      icons: {
        mdiClose,
        mdiEyeOff,
        mdiEye,
        mdiPlusThick,
        mdiPencil,
        mdiFileTree,
        mdiUndo,
      },
    }
  },
  data: () => ({
    items: [],
    isLoading: false,
    search: null,
    show: false,
    loading: false,
    kategoriSecim: [],
    dialogKategori: false,
    eventData: {
      is_active: '1',
      baslik: '',
      content: '',
      kategori: 1,
    },
    defaultItem: {
      is_active: '1',
      baslik: '',
      content: '',
      kategori: 1,
    },
  }),
  computed: {
    formTitle() {
      return this.eventType === 'add' ? 'Yeni Duyuru Ekle' : 'Duyuruyu Düzenle'
    },
    formIcon() {
      return this.eventType === 'add' ? this.icons.mdiPlusThick : this.icons.mdiPencil
    },
    formButtonText() {
      return this.eventType === 'add' ? 'Kaydet' : 'Güncelle'
    },
  },
  watch: {
    isAddUpdateEventSidebarActive(val) {
      if (val && this.eventType === 'edit') {
        this.$nextTick(() => {
          this.eventData = {
            is_active: this.eventItem?.is_active,
            baslik: this.eventItem?.baslik,
            content: this.eventItem?.content,
            kategori: 1,
          }
        })
      }
      if (!val) {
        this.close()
      }
    },
  },

  methods: {
    async submit() {
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.loading = false

        Vue.swal({
          title: 'Hata',
          text: 'Lütfen kırmızı alanları doldurun',
          icon: 'error',
          timer: 2000,
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })

        return
      }

      const data = this.eventData

      const { id } = this.eventItem

      const search = ['baslik', 'content']
      const method = 'saveEvent'
      this.$store
        .dispatch('addUpdateMethod', {
          data,
          search,
          method,
          id,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              text: response.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.$emit('refetch-data')
            this.$emit('update:is-add-update-event-sidebar-active', false)

            this.$emit('save-event-event', {
              message:
                this.eventType === 'add'
                  ? `${this.eventData.baslik} Duyurusu Eklendi`
                  : `${this.eventData.baslik} Duyurusu Düzenlendi`,
              color: 'success',
              timeout: 5000,
            })
            this.loading = false
          }
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          this.$emit('update:is-add-update-event-sidebar-active', false)

          this.loading = false
        })
    },
    close() {
      this.$refs.observer.reset()
      this.$nextTick(() => {
        this.eventData = { ...this.defaultItem }
      })
    },
  },
}
</script>
<style lang="scss">
.tiptap-vuetify-editor {
  .v-card {
    box-shadow: none !important;
  }
}
</style>
