<template>
  <v-row no-gutters>
    <v-col>
      <div id="event-list">
        <!-- app drawer -->

        <event-add-update
          v-model="isAddUpdateEventSidebarActive"
          :event-type="eventType"
          :event-item="eventItem"
          @refetch-data="fetchEvents"
          @upload-image="uploadImage"
          @save-event-event="saveEventEvent"
        ></event-add-update>

        <event-filter v-model="isFilterEventSidebarActive" @filter-data="filterData"> </event-filter>

        <Image-upload
          v-model="isUploadEventImageActive"
          :data="dataUpload"
          @refetch-data="fetchEvents"
          @save-image-event="saveImageEvent"
        />

        <v-snackbars :objects.sync="snackbars" width="500" top right transition="slide-y-transition">
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <!-- list filters -->

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            :color="$vuetify.breakpoint.mdAndDown ? '#3b355a' : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-h6 font-weight-medium">
              <v-icon large left>
                {{ icons.mdiBullhorn }}
              </v-icon>
              Duyurular
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
              depressed
              x-large
              @click="addEvent()"
              @mouseenter="activeHover = true" @mouseleave="activeHover = false"
            >
              <span>Duyuru Ekle</span>
              <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
            </v-btn>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="eventListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :server-items-length="totalEventListTable"
            :loading="loading"
          >
            <!-- Text -->

            <template #[`item.is_active`]="{ item }">
              <v-chip :color="item.is_active === '1' ? 'success' : 'warning'" link outlined small>
                {{ item.is_active === '1' ? 'Aktif' : 'Pasif' }}
              </v-chip>
            </template>
            <template #[`item.content`]="{ item }">
              <div v-text="item.content"></div>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-btn depressed color="nightDark" small class="text-capitalize text-caption" @click="editEvent(item)">
                Düzenle
              </v-btn>

              <v-tooltip bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed color="error" x-small plain v-bind="attrs" @click="eventDelete(item)" v-on="on">
                    <v-icon small>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Duyuru Sil</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImageUpload from '@/components/ImageUpload.vue'
import store from '@/store'
import {
  mdiBullhorn,
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import eventStoreModule from '../eventStoreModule'
import eventAddUpdate from './EventAddUpdate.vue'
import eventFilter from './EventFilter.vue'

export default {
  components: {
    eventAddUpdate,
    eventFilter,
    ImageUpload,
    VSnackbars,
  },
  setup() {
    const EVENT_APP_STORE_MODULE_NAME = 'app-event'
    const snackbars = ref([])

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
      }
    })

    const isAddUpdateEventSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const isFilterEventSidebarActive = ref(false)
    const isUploadEventImageActive = ref(false)
    const dataUpload = ref({})
    const eventListTable = ref([])
    const eventType = ref('add')
    const eventItem = ref({})

    const tableColumns = [
      {
        text: 'Durum',
        value: 'is_active',
        width: '90px',
      },

      {
        text: 'Başlık',
        value: 'baslik',
        width: '200px',
      },

      {
        text: 'İçerik',
        value: 'content',
      },

      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalEventListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const eventTotalLocal = ref([])
    const selectedRows = ref([])

    const eventImageUpload = item => {
      dataUpload.value = item
      isUploadEventImageActive.value = true
    }
    const uploadImage = item => {
      dataUpload.value = item
      isUploadEventImageActive.value = true
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const eventImageDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Resimi silme işlemini onaylayın',
        icon: 'question',
        background: '#FFB400',
        showCancelButton: true,
        showCloseButton: false,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FFB400',
        confirmButtonColor: '#cc9000',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'removeMediaItem',
              uploadid: item.pictures.bresim[0].uploadid,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Duyurusun Resmi Silindi`,
                color: 'error',
                timeout: 3000,
              })

              fetchEvents()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const eventDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Duyurusu silme işlemini onaylayın',
        icon: 'question',
        background: '#FF4C51',
        showCloseButton: false,
        showCancelButton: true,
        width: '300px',
        confirmButtonText: 'Onaylıyorum',
        cancelButtonColor: '#FF4C51',
        confirmButtonColor: '#cc3d41',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'deleteEvent',
              id: item.id,
            })
            .then(() => {
              snackbars.value.push({
                message: `${item.baslik} Duyurusu Silindi`,
                color: 'error',
                timeout: 5000,
              })

              fetchEvents()
            })
            .catch(e => {
              Vue.swal({
                title: 'Hata',
                text: e.message,
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            })
        }
      })
    }

    const saveEventEvent = val => {
      snackbars.value.push(val)
    }
    const saveImageEvent = val => {
      snackbars.value.push(val)
    }

    const editEvent = item => {
      eventItem.value = item
      eventType.value = 'edit'
      isAddUpdateEventSidebarActive.value = true
    }
    const addEvent = () => {
      eventItem.value = {}
      eventType.value = 'add'
      isAddUpdateEventSidebarActive.value = true
    }
    const filterData = item => {
      idFilter.value = item.id || null
      statusFilter.value = item.aktif || null
      urunKodFilter.value = item.urun_kodu || null
      baslikFilter.value = item.baslik || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }

    const fetchEvents = () => {
      const query = {
        options: options.value,
        id: idFilter.value,
        aktif: statusFilter.value,
        urun_kodu: urunKodFilter.value,
        baslik: baslikFilter.value,
        customer: customerFilter.value,
      }
      const method = 'getEvents'
      store
        .dispatch('fetchMethod', { query, method })
        .then(response => {
          eventListTable.value = response.response.result
          totalEventListTable.value = Number(response.response.pagination.totalRec)
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    watch([searchQuery, idFilter, urunKodFilter, statusFilter, baslikFilter, customerFilter, options], () => {
      loading.value = true
      selectedRows.value = []
      fetchEvents()
    })

    return {
      eventImageDelete,
      statusFind,
      eventItem,
      editEvent,
      addEvent,
      eventType,
      isFilterNumber,
      filterData,
      eventListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      idFilter,
      statusFilter,
      totalEventListTable,
      loading,
      options,
      eventTotalLocal,
      isAddUpdateEventSidebarActive,
      isFilterEventSidebarActive,
      isUploadEventImageActive,
      selectedRows,
      fetchEvents,
      eventDelete,
      snackbars,
      eventImageUpload,
      dataUpload,
      uploadImage,
      saveEventEvent,
      saveImageEvent,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiBullhorn,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      
      activeHover : false
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
